<template lang="pug">
    v-container.custom-container()
        v-layout(column align-center)
            v-layout(column xs12='' align-center).pt-2.ma-2.mt-3.text-center
                .title(style="color: rgb(76,76,76); font-size: 2.2rem !important; letter-spacing: -1.5px !important;") {{title}}
                v-divider.primary.mt-5( style="width: 100px;")

            v-layout(column style="font-size: 15px;" ).text-xs-justify.pa-3

                p.py-2.text-justify
                  h2.mr-2 Welcome to our Newsroom!
                  | We currently have no news update to report, stay tuned.

</template>

<script>
  export default {
    data () {
      return {
        title: 'News',
      }
    },

    computed: {

    },

  }
</script>

<style lang='scss' scoped>

</style>
